var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import { getFirestore, collection, addDoc, getDocs, where, query, onSnapshot } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { Button, Spin } from 'antd';
var prefix = 'stripe/checkout';
export var LoadProductsAndPlans = function () { return __awaiter(void 0, void 0, void 0, function () {
    var db, productRef, snap, products, plans, promises;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                db = getFirestore();
                productRef = collection(db, "".concat(prefix, "/products"));
                return [4 /*yield*/, getDocs(query(productRef, where('active', '==', true)))];
            case 1:
                snap = _a.sent();
                products = [];
                plans = [];
                snap.forEach(function (doc) { return __awaiter(void 0, void 0, void 0, function () {
                    var product;
                    return __generator(this, function (_a) {
                        product = doc.data();
                        product.id = doc.id;
                        product.prices = {};
                        // Publish a product in Stripe dashboard by adding a
                        // 'firebaseRole' key in the metadata.
                        if ('firebaseRole' in product.metadata) {
                            products.push(product);
                        }
                        return [2 /*return*/];
                    });
                }); });
                promises = products.map(function (product) {
                    var priceRef = collection(db, "".concat(prefix, "/products/").concat(product.id, "/prices"));
                    return getDocs(query(priceRef, where('active', '==', true), where('recurring.interval', 'in', ['month', 'year']), where('recurring.interval_count', '==', 1)));
                });
                return [2 /*return*/, Promise.all(promises).then(function (snaps) {
                        snaps.forEach(function (snap) {
                            snap.forEach(function (doc) {
                                var price = doc.data();
                                price.id = doc.id;
                                plans.push(price);
                            });
                        });
                        return { plans: plans, products: products };
                    })];
        }
    });
}); };
export var Checkout = function (_a) {
    var priceId = _a.priceId, successUrl = _a.successUrl;
    var user = getAuth().currentUser;
    var _b = useState(null), docRef = _b[0], setDocRef = _b[1];
    useEffect(function () {
        var createSession = function () { return __awaiter(void 0, void 0, void 0, function () {
            var db, sessionsCollection, ref;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        db = getFirestore();
                        sessionsCollection = collection(db, "".concat(prefix, "/customers/").concat(user.uid, "/checkout_sessions"));
                        return [4 /*yield*/, addDoc(sessionsCollection, {
                                price: priceId,
                                success_url: window.location.origin + successUrl,
                                cancel_url: window.location.href,
                            })];
                    case 1:
                        ref = _a.sent();
                        setDocRef(ref);
                        return [2 /*return*/];
                }
            });
        }); };
        createSession();
    }, [user.uid, priceId]);
    useEffect(function () {
        if (docRef === null) {
            return;
        }
        // Wait for the CheckoutSession to get attached by the extension
        return onSnapshot(docRef, function (snap) {
            // @ts-ignore
            var _a = snap.data(), error = _a.error, url = _a.url;
            if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                alert("An error occured: ".concat(error.message));
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                window.location.assign(url);
            }
        });
    }, [docRef]);
    return React.createElement(React.Fragment, null,
        React.createElement(Button, { type: "primary", htmlType: "submit", style: { marginLeft: 0 } }, "Loading..."),
        React.createElement(Spin, { style: { marginLeft: '10px' } }));
};
