import React, { createContext, useContext, useState } from 'react';
import { ThemeProvider } from "styled-components";
var ThemeContext = createContext(null);
export var Theme = function (_a) {
    var children = _a.children, defaultTheme = _a.defaultTheme;
    var _b = useState(defaultTheme), theme = _b[0], setTheme = _b[1];
    return (React.createElement(ThemeContext.Provider, { value: setTheme },
        React.createElement(ThemeProvider, { theme: theme }, children)));
};
export var ThemeSwitch = function (_a) {
    var children = _a.children, theme = _a.theme;
    var setTheme = useContext(ThemeContext);
    var onclick = function (e, theme) {
        e.preventDefault();
        setTheme(theme);
    };
    return React.createElement("span", { onClick: function (e) { return onclick(e, theme); } }, children);
};
