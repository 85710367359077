import styled from 'styled-components';

export const FormCard = styled.div`
  margin: 25px 0;
  input,
  textarea {
    background: ${({ theme }) => theme.text.input};
    border: ${({ theme }) => theme.card.border};
    color: ${({ theme }) => theme.text.primary};
  }
  input::placeholder,
  textarea::placeholder {
    color: ${({ theme }) => theme.text.secondary};
  }
  label {
    color: ${({ theme }) => theme.text.primary}; 
  }
  button {
    margin-left: 15px;
  }
`;

export const Card = styled.div`
  background: ${({ theme }) => theme.card.background};
  border: ${({ theme }) => theme.card.border};
  padding: 10px 15px;
  margin: 15px 0;
  border-radius: 10px;

  a {
    color: ${({ theme }) => theme.text.accent};
  }

  p {
    margin-top: 0;    
    color: ${({ theme }) => theme.text.primary}
  }

  .author {
    margin-left: -5px;
    color: ${({ theme }) => theme.text.secondary };
  }

  .footer {
    * {
      font-size: 9pt;
    }

    * svg {
      font-size: 16pt;
      margin-right: 5px;
    }

    > * {
      margin-right: 15px;
    }

    > *:last-child {
      margin-right: 0;
    }
  }

  .align-right {
    margin-left: auto;
  }

  .flat-button {
    cursor: pointer;
    align-self: start;
    padding: 5px 10px;
    border-radius: 10px;
    color: ${({ theme }) => theme.text.primary };
    background-color: ${({ theme }) => theme.text.input };
  }
`;
