import React, { createContext, useEffect, useState } from 'react';
import { getFirestore, doc, getDoc } from "firebase/firestore";

import { getAuth } from "firebase/auth";

export type User = {
  id: string;
  name: string;
  defaultOrgId: string;
  createdAt: number;
  roles: Role;
};

export interface Role {
  [id: string] : string;
}

export const UserContext = createContext<User | null>(null);

export const getUser = async () => {
  const auth = getAuth().currentUser!;

  const f = () =>  {
    const db = getFirestore();
    const userRef = doc(db, "users", auth.uid);
    return getDoc(userRef);
  };

  const snap = await f();
  if (snap.exists()) {
    return snap.data() as User
  } else {
    return null;
  }
};

type Props = {
  children: React.ReactNode;
};

export const UserProvider: React.FC<Props> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);

  const load = async () => {
    const user = await getUser();
    setUser(user);
  };

  useEffect(() => { load() }, []);

  if (user === null) {
    return <div />;
  }

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
};