export var lightTheme = {
    body: '#ddd',
    nav: {
        background: '#bbb',
        color: '#333',
    },
    text: {
        accent: '#04A7E0',
        primary: '#000',
        secondary: '#333',
        input: '#eee',
    },
    card: {
        background: '#ddd',
        border: '1px solid #ccc',
    },
};
export var darkTheme = {
    body: '#222',
    nav: {
        background: '#191919',
        color: '#fff',
    },
    text: {
        accent: '#3AB4F2',
        primary: '#ddd',
        secondary: '#aaa',
        input: '#333',
    },
    card: {
        background: 'none',
        border: '1px solid #444',
    },
};
