import { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { getAuth } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc, getDocs } from "firebase/firestore";
import { collection, query, orderBy , runTransaction } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { FormatDate, generateUID } from './utils';
import { Card, FormCard } from './style';
import FlipMove from 'react-flip-move';

// @ts-ignore
import { Row } from 'saas39.com';

import { Button, Form, Input, Checkbox } from 'antd';
import PersonIcon from '@mui/icons-material/Person';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

type Question = {
  id?: string;
  title: string;
  votes: {
    up: number;
    down: number;
  };
  author: string | null;
  createdAt: number,
};

interface VotesHash {
  [id: string] : number;
}

// TODO: move to configuration file
const pusherHost = 'pusher.elasticsocket.com';

export default function Main() {
  const { eventId } = useParams<{ eventId: string }>();
  const [questions, setQuestions] = useState<Question[]>([]);
  const socketURL = "wss://" + pusherHost + "/ws/" + eventId;

  const { sendMessage } = useWebSocket(socketURL, {
    onOpen: () => {
      const load = async () => {
        const db = getFirestore();
        const ref = query(collection(db, `events/${eventId}/questions`), orderBy("votes.up", "desc"));

        const snap = await getDocs(ref)
        const questions: Question[] = [];

        snap.forEach(q => { questions.push(q.data() as Question) });
        setQuestions(questions);
      };

      load();
    },

    onMessage: (event: WebSocketEventMap['message']) => {
      const { type, data } = JSON.parse(event.data);

      if (type === 'new') {
        const question = data as Question;
        const newQuestions = [question, ...questions];
        setQuestions(newQuestions);
      } else if (type === 'like' || type === 'unlike') {
        const { questionId } = data;
        const index = questions.findIndex((q) => q.id === questionId);

        const question = questions[index];
        question.votes.up += (type === 'like') ? 1 : -1

        const newQuestions = [...questions];
        newQuestions[index] = {...question};

        newQuestions.sort((a: Question, b: Question): number => b.votes.up - a.votes.up)
        setQuestions(newQuestions);
      }
    },

    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  return <>
    <NewQuestion eventId={eventId!} sendMessage={sendMessage} />

    <FlipMove>
      {questions.map((question: Question) => (
        <Card key={`questions-${question.id}`}>
          <p>{question.title}</p>
          <Row className="footer">
            <Row className="author">
              <PersonIcon />
              <div>
                <div>{question.author || 'Anonymous'}</div>
                <FormatDate t={question.createdAt} />
              </div>
            </Row>
            <Votes eventId={eventId!} question={question} sendMessage={sendMessage} />
          </Row>
        </Card>
      ))}
    </FlipMove>
  </>;
};

function NewQuestion({ eventId, sendMessage }: { eventId: string, sendMessage: any}) {
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const onFinish = async function (values: any) {
    const db = getFirestore();
    const user = getAuth().currentUser!;

    // TODO: add validation
    const question: Question = {
      id: generateUID(),
      title: values.question,
      author: values.anonymous === true ? null : user.displayName,
      votes: {
        up: 0,
        down: 0,
      },
      createdAt: Date.now(),
    };

    const questionsRef = collection(db, `events/${eventId}/questions`);
    await setDoc(doc(questionsRef, question.id), question);

    const message = { type: 'new', data: question };
    sendMessage(JSON.stringify(message));
    
    form.resetFields();
    setEdit(false);
  };

  const onValuesChange = (values: any) => {
    if ('question' in values) {
      setEdit(values.question.length > 0);
    }
  };

  const [edit, setEdit] = useState<boolean>(false)
  
  return <FormCard>
    <Form
      form={form}
      initialValues={{ anonymous: true }}      
      layout="vertical"
      onFinish={onFinish}
      onValuesChange={onValuesChange}      
    >
      <Form.Item name="question" noStyle>
        <TextArea 
          rows={2}
          placeholder="Type your question">
        </TextArea> 
      </Form.Item>
      {edit === true && (
        <Row>
          <div style={{marginLeft: 'auto', marginTop: '15px'}}>
            <Form.Item name="anonymous" valuePropName="checked" noStyle>
              <Checkbox>Anonymous</Checkbox>
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Row>
      )}
    </Form>
  </FormCard>
};

function Votes({ eventId, question, sendMessage }: { eventId: string, question: Question, sendMessage: any}) {
  const db = getFirestore();
  const auth = getAuth().currentUser!;    
  const [vote, setVote] = useState<string>('');

  const questionPath = `events/${eventId}/questions/${question.id}`;
  const votesPath = `${questionPath}/votes/${auth.uid}`;

  useEffect(() => {
    const load = async function() {
      const ref = doc(db, votesPath);
      const snap = await getDoc(ref);
      const vote = snap.data() || {};
      setVote(vote.value);
    };

    load();
  }, [db, eventId, question, votesPath]);

  const clickThumb = async (e: any, newVote: string) => {
    e.preventDefault();

    if (vote !== newVote) {  
      setVote(newVote);
    } else {
      setVote('');
    }

    await runTransaction(db, async (transaction) => {
      const qref = doc(db, questionPath);
      const vref = doc(db, votesPath);      
      const qdoc = await transaction.get(qref);
      const votes = qdoc.get('votes') as VotesHash;

      if (newVote === vote) {
        if (votes[newVote] > 0) {
          votes[newVote] -= 1;
          const message = {type: 'unlike', data: { questionId: question.id }};
          sendMessage(JSON.stringify(message));
        }
      } else {
        votes[newVote] += 1;
        const message = {type: 'like', data: { questionId: question.id }};
        sendMessage(JSON.stringify(message));
        if (votes[vote] > 0) {
          votes[vote] -= 1;
        }        
      }
      
      transaction.update(qref, {votes: votes});

      if (newVote === vote) {
        transaction.delete(vref);
      } else {
        transaction.set(vref, {value: newVote});
      }
    });
  };

  return <>
    <Row className="flat-button align-right" onClick={(e: any) => clickThumb(e, 'up')}>
      {(vote === 'up') && (
        <ThumbUpIcon />
      )}
      {(vote !== 'up') && (
        <ThumbUpOffAltIcon />
      )}
      <span>{question.votes.up}</span>
    </Row>
  </>;
};
