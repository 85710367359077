import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { getAuth } from "firebase/auth";
import { getFirestore, doc, addDoc, setDoc, getDoc, collection } from "firebase/firestore";
import { Button, Form, Input } from 'antd';

import { LoadProductsAndPlans, Checkout } from 'saas39.com/checkout';
import { User, Role } from './users';
import { FormCard } from './style';
import { FormatCurrency } from './utils';

export type Org = {
  name: string;
  ownerId: string;
  users: {
    admins: string[];
  };
  createdAt: number;
};

export function CheckoutForm() {
  const { priceId } = useParams<{ priceId: string }>();

  const db = getFirestore();
  const navigate = useNavigate();
  const [confirmedPriceId, setConfirmedPriceId ] = useState<string | null>(null);
  const [{price, product}, setPlan] = useState<any | null>({price: null, product: null});

  useEffect(() => {
    LoadProductsAndPlans().then(({ plans, products }: { plans: any, products: any }) => {
      const price = plans.find((p: any) => p.id === priceId);
      const product = products.find((p: any) => p.id === price.product);
      setPlan({product, price});
    });;
  }, [db, priceId]);

  const onFinish = async function (values: any) {
    const db = getFirestore();
    const auth = getAuth().currentUser!;

    // TODO: add validation
    const org: Org = {
      name: values.name,
      ownerId: auth.uid,
      users: {
        admins: [auth.uid]
      },
      createdAt: Date.now(),
    };

    const { id } = await addDoc(collection(db, `organizations`), org);

    const roles: Role = {};
    roles[id] = 'admin';

    const user: User = {
      id: auth.uid,
      name: auth.displayName!,
      defaultOrgId: id,
      createdAt: Date.now(),
      roles: roles,
    };

    await setDoc(doc(db, `users`, auth.uid), user);

    if (price.unit_amount > 0) {
      setConfirmedPriceId(price.id);
    } else {
      form.resetFields();
      navigate('/');
    }
  };

  const [form] = Form.useForm();

  if (price === null) {
    return null;
  }

  return <FormCard>
    <h1>Thank for joining us!</h1>
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
    >
      <div style={{marginTop: '40px'}}>
        <div>
          <label>Business/Team name:</label>
        </div>
        <Form.Item
          name="name"
          label=""
          rules={[{ required: true, message: 'Please, provide a value!' }]}
          >
          <Input placeholder="Type a name..." />
        </Form.Item>
      </div>

      <div style={{marginTop: '40px'}}>
        <div><label> </label></div>
        <div>
          <label style={{ marginRight: '15px' }}>
            You have selected the <strong>{product.name}</strong> Plan.
            The cost of this plan is {FormatCurrency(price.unit_amount)} / {price.interval}.
          </label>
        </div>
      </div>

      <div style={{marginTop: '40px'}}>
        {!confirmedPriceId && (
          <Button type="primary" htmlType="submit" style={{marginLeft: 0}}>
            {price.unit_amount > 0 ? (
              `Checkout (${ FormatCurrency(price.unit_amount) } / ${ price.interval })`
            ) : (
              "Create account"
            )}
          </Button>
        )}
        {confirmedPriceId && (
          <Checkout priceId={confirmedPriceId} successUrl={'/join/completed'} />
        )}
      </div>
    </Form>
  </FormCard>
};

export function NewParticipant() {
  const navigate = useNavigate();
  const { code } = useParams<{ code: string }>()
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const load = async function() {
      const db = getFirestore();

      // TODO: should we create a user with participant role?
      const path = `events/${code}`;
      const  ref = doc(db, path);
      const  snap = await getDoc(ref);

      if (snap.exists()) {
        navigate(`/events/${snap.id}`);
      } else {
        setError("Invalid invitation");
      }
    };

    load();
  }, [code, navigate]);

  return <p>{error}</p>;
};