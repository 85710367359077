import React, { useContext } from 'react';
import { Link, redirect } from "react-router-dom";
import { useEffect, useState } from 'react';
import { message } from 'antd';

import { getFirestore, doc, runTransaction } from "firebase/firestore";
import { collection, query, where, onSnapshot, orderBy } from "firebase/firestore";

// @ts-ignore
import { Row } from 'saas39.com';
import { Card, FormCard } from './style';
import { FormatDate, generateUID } from './utils';
import { User, UserContext } from './users';

import { Button, Form, Input } from 'antd';
import PersonIcon from '@mui/icons-material/Person';
import ShareIcon from '@mui/icons-material/Share';

type Event = {
  id: string;
  orgId: string;
  name: string;
  owner: {
    id: string,
    name: string,
  },  
  createdAt: number,
};

export default function Main() {
  const [events, setEvents] = useState<Event[]>([]);
  const user: User = useContext(UserContext)!;

  useEffect(() => {
    const db = getFirestore();
    const eventRef = collection(db, "events")
    const q = query(eventRef, where("orgId", "==", user.defaultOrgId), orderBy("createdAt", "desc"));

    return onSnapshot(q, (snap) => {
      const events: Event[] = [];

      snap.forEach(q => {
        events.push(q.data() as Event);
      });

      setEvents(events);
    });
  }, [user.defaultOrgId]);

  const onTouchStart = (eventId: string) => {
    redirect(`/events/${eventId}`);
  };

  return <>
    <NewEvent />
    {events.map((event: Event) => (
      <Card key={`events-${event.id}`} onTouchStart={() => { onTouchStart(event.id!) }}>
        <Row>
          <p>
            <Link to={`/events/${event.id}`}>{event.name}</Link>
          </p>
        </Row>

        <Row className="footer">
          <Row className="author">
            <PersonIcon />
            <div>
              <span>{(event.owner || {}).name || 'Anonymous'}</span>
              <FormatDate t={event.createdAt} />
            </div>
          </Row>
          <ShareEvent path={`/join/participant/${event.id}`} />
        </Row>
      </Card>
    ))}
  </>;
};

function ShareEvent({ path }: { path: string }) {
  const origin = window.location.origin.toString();
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: 'success',
      content: `
        The event link has been copied to the clipboard. Please, share this link
        with the relevant participants (via Slack, email, etc...)
      `,
      duration: 7,
    });
  };

  const onClick = (e: any) => {
    navigator.clipboard.writeText(`${origin}${path}`).then(success);
    e.preventDefault();
    return false;
  };

  return <Row className="flat-button align-right" onClick={onClick}>
    {contextHolder}
    <ShareIcon /><span>Share</span>
  </Row>
}

function NewEvent() {
  const db = getFirestore();
  const user: User = useContext(UserContext)!;

  const onFinish = async function (values: any) {
    // TODO: add validation

    await runTransaction(db, async (transaction) => {
      const event: Event = {
        id: generateUID(),
        name: values.name,
        orgId: user.defaultOrgId,
        createdAt: Date.now(),
        owner: {
          id: user.id,
          name: user.name,
        }
      };

      const eventRef = doc(db, "events", event.id);
      transaction.set(eventRef, event);

      form.resetFields();
    })      
  };

  const [form] = Form.useForm();

  return <FormCard>
    <Form
      form={form}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="vertical"
      onFinish={onFinish}
    >
      <Row>
        <Form.Item name="name" noStyle>
          <Input placeholder="Type an event name" />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Row>
    </Form>
  </FormCard>
};
