var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { createGlobalStyle } from 'styled-components';
export var GlobalStyle = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  body {\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n      sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n\n    margin: 0;\n\n    background: ", ";\n    color: ", ";    \n  }\n\n  #root li.ant-menu-item:hover,\n  #root li.ant-menu-item-selected {\n    color: ", ";\n  }\n\n  .ant-dropdown {\n    .ant-dropdown-menu {\n      background: ", ";      \n    }\n\n    .ant-dropdown-menu * {\n        color: ", ";\n      }\n    }\n\n    .ant-dropdown-menu svg {\n      padding: 5px 0;\n      width: 30px;\n      margin-right: 20px;\n    }\n  }\n"], ["\n  body {\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n      sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n\n    margin: 0;\n\n    background: ", ";\n    color: ", ";    \n  }\n\n  #root li.ant-menu-item:hover,\n  #root li.ant-menu-item-selected {\n    color: ", ";\n  }\n\n  .ant-dropdown {\n    .ant-dropdown-menu {\n      background: ", ";      \n    }\n\n    .ant-dropdown-menu * {\n        color: ", ";\n      }\n    }\n\n    .ant-dropdown-menu svg {\n      padding: 5px 0;\n      width: 30px;\n      margin-right: 20px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.body;
}, function (_a) {
    var theme = _a.theme;
    return theme.text.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.nav.color;
}, function (_a) {
    var theme = _a.theme;
    return theme.nav.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.text.primary;
});
export var Main = styled.main(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 12pt;\n  min-height: 100vh;\n  padding: 0px 25px;\n  flex: auto;\n\n  @media (min-width:700px) {\n    width: 700px;\n    margin: 0 auto;\n  }\n"], ["\n  font-size: 12pt;\n  min-height: 100vh;\n  padding: 0px 25px;\n  flex: auto;\n\n  @media (min-width:700px) {\n    width: 700px;\n    margin: 0 auto;\n  }\n"])));
export var Footer = styled.footer(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: fixed;\n  bottom: 5px;\n  right: 15px;\n  font-size: 8pt;\n\n  color: ", ";\n"], ["\n  position: fixed;\n  bottom: 5px;\n  right: 15px;\n  font-size: 8pt;\n\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.text.secondary;
});
export var Header = styled.header(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: sticky;\n  top: 0;\n  z-index: 1;\n  padding: 0 20px;\n  background: ", ";    \n\n  ul {\n    flex: 1;\n    background: ", ";\n  }\n\n  li.ant-menu-item {\n    color: ", ";\n    display: flex;\n\n    * {\n      display: flex;\n      align-items: center;      \n    }\n\n    svg {\n      margin-right: 10px;\n    }\n  }\n\n  .account-menu {\n    cursor: pointer;\n    padding: 10px 0;\n  }\n"], ["\n  position: sticky;\n  top: 0;\n  z-index: 1;\n  padding: 0 20px;\n  background: ", ";    \n\n  ul {\n    flex: 1;\n    background: ", ";\n  }\n\n  li.ant-menu-item {\n    color: ", ";\n    display: flex;\n\n    * {\n      display: flex;\n      align-items: center;      \n    }\n\n    svg {\n      margin-right: 10px;\n    }\n  }\n\n  .account-menu {\n    cursor: pointer;\n    padding: 10px 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.nav.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.nav.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.nav.color;
});
export var Row = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
