import React from 'react';
import { MenuProps } from 'antd';
import {
  Routes,
  Route,
  Link,
  Navigate,
  useLocation,
} from "react-router-dom";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EventIcon from '@mui/icons-material/Event';

import RenderApp, { NavBar } from 'saas39.com';
import { Header, Main, Footer } from 'saas39.com';
import { RequireAuth, Signin } from 'saas39.com';

import { UserProvider } from './users';
import { CheckoutForm, NewParticipant } from './onboarding';
import Events from './events';
import Questions from './questions';

import reportWebVitals from './reportWebVitals';

declare global {
  interface Window {
    firebaseConfig: any;
  }
}

const start = () => {
  // Render the app
  RenderApp(App, window.firebaseConfig);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
};

export default function App({ user }: { user: any }) {
  const pathname = useLocation().pathname;

  return (
    <>
      <Header>
        {(user !== null && pathname.startsWith('/events')) && (
          <NavBar items={ MainMenuItems() } />
        )}
      </Header>
      <Main>
        <Routes>
          <Route path="/" element={
            <Navigate to="/events" />
          }/>

          <Route path="/events" element={
            <RequireAuth>
              <UserProvider><Events /></UserProvider>
            </RequireAuth>
          }/>

          <Route path="/events/:eventId" element={
            <RequireAuth><Questions /></RequireAuth>
          }/>

          <Route path="/signin" element={
            <div style={{ textAlign: 'center', marginTop: '100px'}}>
              <h1>Please sign in to continue</h1>
              <Signin defaultRedirectUrl={'/events'} />
            </div>
          }/>

          <Route path="join/participant/:code" element={
            <RequireAuth><NewParticipant /></RequireAuth>
          }/>

          <Route path="/join/completed" element={
            <Navigate to="/events" />
          }/>

          <Route path="join/:priceId" element={
            <RequireAuth><CheckoutForm /></RequireAuth>
          }/>

          <Route path="*" element={
            <div>Not found page</div>
          }/>
        </Routes>
      </Main>
      <Footer>
        MeetQ&A ©2023
      </Footer>
    </>
  );
};

function MainMenuItems() {
  const pathname = useLocation().pathname;

  const items: MenuProps['items'] = [
    {
      label: (
        <Link to="/events">
          {!pathname.startsWith('/events/') && (
            <>
              <EventIcon />
              <span>Events</span>
            </>
          )}

          {pathname.startsWith('/events/') && (
            <>
              <ArrowBackIosIcon />
              <span>Back</span>
            </>
          )}
        </Link>
      ),
      key: 0,
    }
  ];

  return items;
};

start();
