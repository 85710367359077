import React from 'react';
import { Dropdown, Menu } from 'antd';
import PersonIcon from '@mui/icons-material/Person';
import Logout from '@mui/icons-material/Logout';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
// @ts-ignore
import { Row } from './style.tsx';
// @ts-ignore
import { ThemeSwitch } from './theme-manager.tsx';
// @ts-ignore
import { darkTheme, lightTheme } from './themes.tsx';
// @ts-ignore
import { Signout } from './auth.tsx';
export var NavBar = function (_a) {
    var items = _a.items, profileItems = _a.profileItems;
    return (React.createElement(Row, null,
        React.createElement(Menu, { mode: "horizontal", items: items }),
        React.createElement(ProfileMenu, { items: profileItems || defaultProfile() })));
};
export var ProfileMenu = function (_a) {
    var items = _a.items;
    return (React.createElement(Dropdown, { menu: { items: items }, trigger: ['click'] },
        React.createElement(PersonIcon, { onClick: function (e) { return e.preventDefault(); }, className: "account-menu" })));
};
function defaultProfile() {
    var items = [
        {
            label: (React.createElement(ThemeSwitch, { theme: darkTheme },
                React.createElement(Row, null,
                    React.createElement(DarkModeIcon, null),
                    "Dark Mode"))),
            key: '0',
        },
        {
            label: (React.createElement(ThemeSwitch, { theme: lightTheme },
                React.createElement(Row, null,
                    React.createElement(LightModeIcon, null),
                    "Light Mode"))),
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: (React.createElement(Row, null,
                React.createElement(Logout, { fontSize: "small" }),
                React.createElement(Signout, null))),
            key: '3',
        },
    ];
    return items;
}
;
