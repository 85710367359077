import React, { useEffect } from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
// Redirects to the login screen if you're not yet authenticated.
export function RequireAuth(_a) {
    var children = _a.children;
    var user = getAuth().currentUser;
    var location = useLocation();
    if (user === null) {
        // Redirect them to the /signin page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they signin, which is a nicer user experience
        // than dropping them off on the home page.
        return React.createElement(Navigate, { to: "/signin", state: { from: location.pathname + location.search } });
    }
    return children;
}
;
export function Signin(_a) {
    var defaultRedirectUrl = _a.defaultRedirectUrl;
    var user = getAuth().currentUser;
    var state = useLocation().state;
    var redirectUrl = state === null ? defaultRedirectUrl : state.from;
    if (user === null) {
        return React.createElement(SigninForm, { successUrl: redirectUrl });
    }
    else {
        return React.createElement(Navigate, { to: redirectUrl });
    }
}
;
function SigninForm(_a) {
    var successUrl = _a.successUrl;
    useEffect(function () {
        var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(getAuth());
        ui.start('#firebaseui-auth', {
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            ],
            signInFlow: 'popup',
            signInSuccessUrl: successUrl,
        });
    }, [successUrl]);
    return React.createElement("div", { id: "firebaseui-auth" });
}
export function Signout() {
    var onClick = function (e) {
        e.preventDefault();
        getAuth().signOut();
    };
    return React.createElement("span", { onClick: onClick }, "Signout");
}
;
