import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Theme } from "./theme-manager";
import { GlobalStyle } from "./style";
import { darkTheme } from "./themes";
var el = document.getElementById("root");
var root = ReactDOM.createRoot(el);
export default function (App, config, theme) {
    var firebaseApp = initializeApp(config);
    var auth = getAuth(firebaseApp);
    onAuthStateChanged(auth, function (user) {
        // Render the app
        root.render(React.createElement(React.StrictMode, null,
            React.createElement(Theme, { defaultTheme: theme || darkTheme },
                React.createElement(GlobalStyle, null),
                React.createElement(Router, null,
                    React.createElement(App, { user: user })))));
    });
}
;
export * from "./nav";
export * from "./style";
export * from "./themes";
export * from "./theme-manager";
export * from "./auth";
