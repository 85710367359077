export function FormatDate({ t }: { t: number }) {
  const d = new Date(t);

  return (
    <div>
      {d.toLocaleDateString("en-US")}
    </div>
  );
};

export function FormatCurrency(value: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(value / 100.0);
};

export const parseQueryString = (query: string) => {
  return query ? (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params: any, param) => {
      let [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {}
  )
  : {}
};

export const generateUID = () => {
  const now = Date.now() as unknown;
  const ts = (now as string);

  let code = btoa(ts);
  code = code.toString().substring(0, code.toString().length -2);
  return shuffle(code);
};

const shuffle = (str : string) => {
  const chars = str.split('');
  return chars.sort(() => Math.random() - .5).join('');
};
